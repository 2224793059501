import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Identity1 } from '../../../components/images/support/workflow-templates/Identity1.generated';
import { Identity2a } from '../../../components/images/support/workflow-templates/Identity2a.generated';
import { Identity2 } from '../../../components/images/support/workflow-templates/Identity2.generated';
import { Identity3a } from '../../../components/images/support/workflow-templates/Identity3a.generated';
import { Identity3 } from '../../../components/images/support/workflow-templates/Identity3.generated';
import { Identity4 } from '../../../components/images/support/workflow-templates/Identity4.generated';
import { Identity5 } from '../../../components/images/support/workflow-templates/Identity5.generated';
import { Identity6 } from '../../../components/images/support/workflow-templates/Identity6.generated';
import { Identity7 } from '../../../components/images/support/workflow-templates/Identity7.generated';
import { Identity8 } from '../../../components/images/support/workflow-templates/Identity8.generated';
import { Identity9 } from '../../../components/images/support/workflow-templates/Identity9.generated';
import { Identity10 } from '../../../components/images/support/workflow-templates/Identity10.generated';
import { Identity11 } from '../../../components/images/support/workflow-templates/Identity11.generated';
import { Identity12 } from '../../../components/images/support/workflow-templates/Identity12.generated';
import { Identity13 } from '../../../components/images/support/workflow-templates/Identity13.generated';
import { Identity14 } from '../../../components/images/support/workflow-templates/Identity14.generated';
import { Identity17 } from '../../../components/images/support/workflow-templates/Identity17.generated';
import { Identity18 } from '../../../components/images/support/workflow-templates/Identity18.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "client-identity-verification-"
    }}>{`Client Identity Verification 🔎`}</h1>
    <p>{`These workflow templates identify organisations, individuals and their representatives by collecting the following information:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Organisation`}</strong>{` details and appropriate documentation based on the type of entity (individual, sole-trader, partnership, company)`}</li>
      <li parentName="ul"><strong parentName="li">{`Individual`}</strong>{` details and primary and secondary forms of identification`}</li>
      <li parentName="ul"><strong parentName="li">{`Representative`}</strong>{` details and primary and secondary forms of identification`}</li>
    </ul>
    <p>{`This page outlines the following steps to request and approve your first client identity identification:`}</p>
    <ol>
      <li parentName="ol">{`Create a `}<strong parentName="li">{`Client Identity Verification`}</strong>{` workflow and `}<strong parentName="li">{`Individual Identity Verification`}</strong>{` workflow from a template`}</li>
      <li parentName="ol">{`Assign the workflows to an individual`}</li>
      <li parentName="ol">{`Review the documentation provided by the individual`}</li>
      <li parentName="ol">{`Approve the documentation`}</li>
      <li parentName="ol">{`Complete the identification process`}</li>
    </ol>
    <p>{`Note that once an identification workflow is approved / rejected the supplied documents will be deleted to reduce the risks associated with storing personally identifiable information. `}</p>
    <p>{`Let's get started!`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Workflows`}</strong>{` menu and click the `}<strong parentName="li">{`Template`}</strong>{` button.`}</li>
    </ol>

    <Identity1 mdxType="Identity1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Identification is seperated into 2 workflows, `}<strong parentName="li">{`Client Identification`}</strong>{` and `}<strong parentName="li">{`Individual Identification`}</strong>{`.
The reason for this is that an organisation may have multiple individuals that need to be identified.
Having 2 seperate workflows allows you to collect an Individual identification without the need for the Client identification.
In this example we will do both: `}</li>
    </ol>
    <p>{`Click the `}<strong parentName="p">{`Create`}</strong>{` button on the `}<strong parentName="p">{`Client Identity Verification`}</strong>{` template.`}</p>

    <Identity2a mdxType="Identity2a" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Create`}</strong>{` button on the `}<strong parentName="li">{`Individual Identity Verification`}</strong>{` template.`}</li>
    </ol>

    <Identity2 mdxType="Identity2" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click into the `}<strong parentName="li">{`Contacts`}</strong>{` menu and click `}<strong parentName="li">{`New contact`}</strong>{` (you can skip this step if the contact is already created).`}</li>
    </ol>

    <Identity3a mdxType="Identity3a" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Add the contact details and click create. A contact should be the business, organisation or individual we wish to identify.`}</li>
    </ol>

    <Identity3 mdxType="Identity3" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click into the relevant contact.`}</li>
    </ol>

    <Identity4 mdxType="Identity4" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Now we need to add the individual we want to identify. Click `}<strong parentName="li">{`Add individual`}</strong>{`.`}</li>
    </ol>

    <Identity5 mdxType="Identity5" />
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Create new individual`}</strong>{`.`}</li>
    </ol>

    <Identity6 mdxType="Identity6" />
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Complete the individuals information and click `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
    </ol>

    <Identity7 mdxType="Identity7" />
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`The individual is now created. Next step is to assign them the two identification workflows. Click the `}<strong parentName="li">{`Assign`}</strong>{` button.`}</li>
    </ol>

    <Identity8 mdxType="Identity8" />
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`Client Identity Verification`}</strong>{` and the `}<strong parentName="li">{`Individual Identity Verification`}</strong>{` workflow
and click the `}<strong parentName="li">{`Submit`}</strong>{` button. This will send an email to the individual requesting that they login and complete the workflows.`}</li>
    </ol>

    <Identity9 mdxType="Identity9" />
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Click into the workflow to see the steps that will be assigned to the individual.`}</li>
    </ol>

    <Identity10 mdxType="Identity10" />
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Click the tasks to review the process.`}</li>
    </ol>

    <Identity11 mdxType="Identity11" />
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Once the individual has submitted the required information the workflow status should look like this. Click into it`}</li>
    </ol>

    <Identity12 mdxType="Identity12" />
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Click the task to review the submission.`}</li>
    </ol>

    <Identity13 mdxType="Identity13" />
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`View document`}</strong>{` button to review the documentation provided. Use the `}<strong parentName="li">{`approve`}</strong>{` or `}<strong parentName="li">{`reject`}</strong>{` buttons to action accordingly.`}</li>
    </ol>

    <Identity14 mdxType="Identity14" />
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">{`Note that once a document is `}<strong parentName="li">{`approved`}</strong>{` or `}<strong parentName="li">{`rejected`}</strong>{` you are no longer able to review the document.`}</li>
    </ol>

    <Identity17 mdxType="Identity17" />
18. The workflow is now complete.

    <Identity18 mdxType="Identity18" />
    <ol {...{
      "start": 19
    }}>
      <li parentName="ol">{`Complete the same approval process for the other workflow.`}</li>
    </ol>
    <h1 {...{
      "id": "great-work-you-have-identified-your-first-individual-"
    }}>{`Great work, you have identified your first individual 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      